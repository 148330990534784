/* ProfileModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.profile-modal-close-user {
  position: relative;
  top: -9px;
  right: -230px;
  background: none;
  font-size: 20px;

  border: none;
  cursor: pointer;
}

.modal-content-profile-picture {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;

  .upload-picture {
    font-size: 16px;
  }

  .r1-modal {
    display: flex;
    flex-direction: column;
  }

  .r2-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .c1-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .preview-image {
        width: 200px; /* Set width to 30px */
        height: 200px; /* Set height to 30px */
        padding: 20px;
        object-fit: contain;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .avatar-icon {
        width: 200px;
        height: 200px;
        color: #000000;
        padding: 20px;
        margin-bottom: 10px;
      }
    }

    .c2-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      .sbutton-modal {
        padding: 10px 20px;
        background-color: rgba(81, 131, 254, 0.6);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: rgba(81, 131, 254, 1);
        }
      }

      .cbutton-modal {
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
        padding: 10px;
        background-color: rgba(218, 37, 37, 0.6);

        &:hover {
          background-color: rgba(218, 37, 37, 1);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-content-profile-picture {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    /* max-height: 80%; */
    text-align: center;
  }

  .input-profile {
    width: 100%;
  }

  .profile-modal-close-user {
    position: relative;
    left: 50%;
    background: none;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }
}
