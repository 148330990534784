.wrapper {
  width: 420px;
  background: white;
  border: 1px solid rgba(3, 3, 3, 0.589);
  box-shadow: 0 0 10px rgba(3, 3, 3, 0.589);
  border-radius: 10px;
  padding: 30px 40px;
  margin: 20px;
}

.empty-line {
  margin: 24px 0;
}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
}

.wrapper p {
  text-align: center;
  font-size: 16px;
}

.wrapper .input-box-username {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0px 0px -10px 0px;
}

.input-box-username input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-username .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.wrapper .input-box-password-login {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 5px 0px 30px 0px;
}

.input-box-password-login input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-password-login .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.input-box-password-login .password-toggle {
  width: 16%;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background: none;
  border: none;
}

.wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.remember-forgot label input {
  accent-color: #0000;
  margin-right: 4px;
}

.remember-forgot a {
  text-decoration: none;
}

.remember-forgot a:hover {
  text-decoration: underline;
}

.wrapper button {
  width: 100%;
  height: 45px;
  background: #0000;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(3, 3, 3, 0.589);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
}

.wrapper button:hover {
  box-shadow: 0 0 20px rgba(3, 3, 3, 0.8); /* Change box-shadow on hover */
}

.wrapper .register-link {
  font-size: 14.5px;
  text-align: center;
  margin: 20px 0 15px;
}

.register-link p a {
  text-decoration: none;
  font-weight: 600;
}

.register-link p a:hover {
  text-decoration: underline;
}

/* Media query for small devices */
/* @media (min-width: 1921px) {
  .empty-line {
    margin: 24px 0;
  }
  .wrapper {
    margin: 20px;
    width: 27.4vw;
    background: white;
    border: 0.05vw solid rgba(3, 3, 3, 0.589);
    box-shadow: 0 0 0.521vw rgba(3, 3, 3, 0.589);
    border-radius: 0.521vw;
    padding: 1.563vw 2.083vw;
  }

  .wrapper h1 {
    font-size: 2.4vw;
    text-align: center;
  }

  .wrapper p {
    text-align: center;
    font-size: 1vw;
  }

  .wrapper .input-box-username {
    position: relative;
    width: 100%;
    height: 2vw;
    margin: 0vw 0vw 0vw 0vw;
  }

  .input-box-username input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 0.08vw solid rgba(3, 3, 3, 0.589);
    border-radius: 3.7vw;
    font-size: 1.151vw;
    padding: 1.452vw 4.167vw 1.852vw 1.852vw;
  }

  .input-box-username .icon {
    position: absolute;
    right: 1.852vw;
    top: 70%;
    transform: translateY(-30%);
    font-size: 1.481vw;
  }
  .wrapper .input-box-password-login {
    position: relative;
    width: 100%;
    height: 2vw;
    margin: 2.05vw 0vw 2.778vw 0vw;
  }

  .input-box-password-login input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 0.08vw solid rgba(3, 3, 3, 0.589);
    border-radius: 3.7vw;
    font-size: 1.151vw;
    padding: 1.452vw 4.167vw 1.852vw 1.852vw;
  }

  .input-box-password-login .icon {
    position: absolute;
    right: 1.852vw;
    top: 70%;
    transform: translateY(-30%);
    font-size: 1.481vw;
  }

  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 1.043vw;
    margin: 0 0 1.389vw;
  }

  .remember-forgot label input {
    accent-color: #0000;
    margin-right: 0.37vw;
  }

  .remember-forgot a {
    text-decoration: none;
  }

  .remember-forgot a:hover {
    text-decoration: underline;
  }

  .wrapper button {
    width: 100%;
    height: 3.2vw;
    background: #0000;
    border: none;
    outline: none;
    border-radius: 3.7vw;
    box-shadow: 0 0 0.926vw rgba(3, 3, 3, 0.589);
    cursor: pointer;
    font-size: 1.1vw;
    color: #333;
    font-weight: 700;
    transition: box-shadow 0.3s ease;
  }

  .wrapper button:hover {
    box-shadow: 0 0 1.852vw rgba(3, 3, 3, 0.8);
  }

  .wrapper .register-link {
    font-size: 1.343vw;
    text-align: center;
    margin: 1.052vw 0 1.389vw;
  }

  .register-link p a {
    text-decoration: none;
    font-weight: 600;
  }

  .register-link p a:hover {
    text-decoration: underline;
  }

  .checkbox-remember {
    min-width: 1vw;
    aspect-ratio: 1/1;
  }
} */
