.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../../../public/site-background.drawio.png") no-repeat center center fixed;
  background-size: cover;
  /* height: 100vh; */
  width: 100vw;
  overflow-y: auto; /* Add a scrollbar if content overflows */
}
