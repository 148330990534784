.chat {
  flex: 2;
  border-left: 1px solid #00000035;
  border-right: 1px solid #00000035;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .chat-contact-username {
    display: flex;
    font-size: 20px;
    font-weight: bold;
  }

  .icons-chat-top {
    display: flex;
    gap: 20px;

    .icon-chat-top {
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user .texts {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 14px;
    font-weight: 300;
    color: #a5a5a5;
  }
}

/* Define the spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.center {
  padding: 20px;
  padding-bottom: 0px;
  border-top: 1px solid #00000035;
  border-bottom: 1px solid #00000035;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2px;

  .loading-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .spinner {
      margin-bottom: 7px;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top: 4px solid #000;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
    }
  }

  .chat-loading-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .chat-spinner {
      margin-top: 30vh;
      top: 200px;
      left: 200px;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top: 4px solid #000;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
    }
  }

  .message {
    max-width: 70%;
    display: flex;
    gap: 20px;

    &.own {
      align-self: flex-end;
      .texts {
        .contactText {
          display: inline-flex;
          margin-left: auto;
          .chat-image-container {
            .image-container {
              position: relative;
              display: inline-block;
            }

            .upload-overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(255, 255, 255, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
              color: #000;
              font-size: 14px;
              font-weight: bold;
              z-index: 1;
            }

            .message-image {
              border-radius: 10px;
              border: 1px solid #a5a5a5;
              /* padding: 9px; */
              max-width: 400px;
              max-height: 260px;
              height: auto; /* Ensures the aspect ratio is maintained */
              display: block;
            }

            p {
              background-color: #5183fe;
            }

            .consecutive-message {
              border-radius: 18px 3px 3px 18px; /* For right-aligned (own) messages */
            }

            /* Separate message styling: round all corners */
            .separate-message {
              border-radius: 18px 3px 18px 18px; /* All corners rounded */
            }
          }
        }
      }
    }

    .contactImg,
    .empty-placeholder {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }

    .empty-placeholder {
      background-color: transparent; /* Make it transparent or set a color */
    }

    .texts {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;

      p {
        color: white;
        padding: 9px;
      }

      /* Consecutive message styling: round only top-right and bottom-right (or left for own) */
      .consecutive-message {
        border-radius: 3px 18px 18px 3px; /* Default for left-aligned messages */
      }

      /* Separate message styling: round all corners */
      .separate-message {
        border-radius: 3px 18px 18px 18px; /* All corners rounded */
      }

      .consecutive-date {
        margin-bottom: 10px;
      }

      /* Separate message styling: round all corners */
      .separate-date {
        margin-bottom: 10px;
      }

      .contactText {
        display: inline-flex;
        .chat-image-container {
          .message-image {
            border-radius: 10px;
            border: 1px solid #a5a5a5;
            /* padding: 9px; */
            max-width: 400px;
            max-height: 260px;
            height: auto; /* Ensures the aspect ratio is maintained */
            display: block;
          }

          p {
            background-color: #a5a5a5;
          }
        }
      }

      .contactImg,
      .empty-placeholder {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }

      .empty-placeholder {
        background-color: transparent; /* Make it transparent or set a color */
      }
    }

    span {
      font-size: 10px;
    }
  }
}

.images-preview {
  display: flex;
  max-height: 130px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  overflow-y: scroll;
  align-items: center;
}

.image-container img {
  height: 100px;
  border-radius: 5px;
  border: 1px solid #b2b2b2;
  margin: 10px;
}

.delete-button {
  width: 15px;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  position: relative;
  top: -93px;
  right: 29px;
}

.delete-button:hover {
  background: #d1d1d1;
}

.bottom {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: auto;

  .icons-chat-bot {
    display: flex;
    gap: 15px;

    .icon-chat-bot {
      font-size: 16px; /* Relative size to ensure scaling with zoom */
      cursor: pointer;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5); /* Optional background overlay */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    position: relative; /* Important for absolute positioning of close button */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .close-btn {
    position: absolute;
    top: 10px; /* Adjust as necessary */
    right: 10px; /* Adjust as necessary */
    background: none;
    border: none;
    cursor: pointer;
  }

  .close-mark {
    font-size: 20px; /* Adjust size of the close icon */
    color: #333; /* Adjust color of the close icon */
  }

  .select-images {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .pop-up-row-2 input {
    width: 100%;
  }
}

.bottom input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00000035;
  font-size: 16px;
  outline: none;
  /* max-width: 40%; */
}

.sendButton {
  background-color: #5183fe;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.emojiIcon {
  cursor: pointer;
}

.emoji {
  position: relative;
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center;
  font-size: 14px;

  .picker {
    position: absolute;
    bottom: 60px;
    left: 0;
    transform: translateX(-70%); /* Offset by 50% to center the picker */

    z-index: 1;
  }
}

@media (max-width: 768px) {
  .bottom {
    width: 100vw;
  }
  .bottom input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #00000035;
    font-size: 16px;
    outline: none;
    max-width: 35%;
  }

  .emoji {
    position: relative;

    .picker {
      width: 90vw; /* Set the width to 90% of the viewport width */
      left: 50%; /* Start positioning from the center of the parent */
      transform: translateX(-70%); /* Offset by 50% to center the picker */
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 2;
  }

  .popup-content {
    background-color: white;
    width: 75%;
    padding: 20px;
    border-radius: 8px;

    input {
      max-width: 90%;
    }
  }

  .close-popup {
    padding: 5px;
    margin: 10px;
    border: 1px solid #b2b2b2;
    border-radius: 5px;
    max-width: 40%;
  }

  .pop-up-row-2 {
    flex-direction: column;
    justify-content: center;
  }

  .message-image {
    border-radius: 10px;
    border: 1px solid #a5a5a5;
    /* padding: 9px; */
    max-width: 230px !important;
    max-height: 260px;
    height: auto; /* Ensures the aspect ratio is maintained */
    display: block;
  }
}
