/* ChatPage.css */
@media (min-width: 769px) {
  .chatPage {
    display: flex;
  }

  .list {
    flex: 1;
    background-color: #fff;
  }

  .chat {
    flex: 2;
    background-color: #fff;
    transition: flex 0.3s ease; /* Smooth transition */
  }

  .details {
    flex: 1;
    background-color: #fff;
    position: relative;
    transition: flex 0.3s ease, width 0.3s ease;
    overflow: hidden;
  }

  .details.collapsed {
    flex: 0;
    width: 0;
  }

  .chat.expanded {
    flex: 3;
  }

  /* Toggle Button */
  .toggle-button-expand {
    position: fixed;
    background-color: transparent;
    top: 0px; /* Adjust spacing from the top edge */
    right: 0px; /* Adjust spacing from the right edge */
    width: 40px; /* Adjusted button size */
    height: 40px; /* Adjusted button size */
    border: none;
    cursor: pointer;
    z-index: 1000; /* Ensures it is above other elements */
  }

  .toggle-button.chat-button,
  .toggle-button.list-button,
  .toggle-button.details-button {
    display: none;
  }
}

/* CSS for smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  .chatPage {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure it takes the full height */
  }

  .list,
  .chat,
  .details {
    flex: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: transform 0.3s ease;
    overflow: hidden;
  }

  .list {
    z-index: 3;
    transform: translateX(0); /* Initially show the list */
  }

  .chat {
    z-index: 2;
    transform: translateX(100%); /* Initially hide the chat */
  }

  .details {
    z-index: 4;
    transform: translateX(100%); /* Initially hide the details */
  }

  .hide-page {
    display: none;
  }

  .show-chat .list {
    transform: translateX(-100%);
  }

  .show-chat .chat {
    transform: translateX(0);
  }

  .show-details .chat {
    transform: translateX(-100%);
  }

  .show-details .details {
    transform: translateX(0);
  }

  /* Buttons */
  .toggle-button {
    display: block;
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }

  .toggle-button-expand {
    display: none;
  }

  .list-button {
    top: 10px;
    left: 10px;
  }

  .chat-button {
    top: 10px;
    right: 10px;
  }

  .details-button {
    top: 10px;
    right: 10px;
  }
}
