.chat-list-container {
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.chat-list {
  overflow-y: auto; /* Only the chat list should scroll */
  flex: 1; /* This will allow it to take up the remaining space and scroll */

  .searchResult {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0px 10px 0px 10px;
    border-bottom: 1px solid #00000035;
    cursor: pointer;

    img {
      padding: 10px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }

    .addButton {
      margin-left: auto; /* Pushes the button to the far right */
      padding: 8px 12px;
      background-color: #007bff; /* Example button color */
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .cancelButton {
      margin-left: auto; /* Pushes the button to the far right */
      padding: 8px 12px;
      background-color: rgba(218, 37, 37, 1);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .contactResult {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 10px 10px 10px;
    border-bottom: 1px solid #00000035;
    cursor: pointer;

    img {
      padding: 5px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
      flex-shrink: 0;
    }

    .cancelButton {
      margin-left: auto; /* Pushes the button to the far right */
      padding: 8px 12px;
      background-color: rgba(218, 37, 37, 1);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .contactUsername {
      font-size: 14px;
      align-self: center;
    }
  }
}

.chat-list-categories {
  .all-button,
  .conversations-button,
  .contacts-button,
  .friend-requests-button {
    margin: 5px 0px 0px 5px;
    padding: 5px;
    border: 1px solid #00000035;
    border-radius: 5px;
    cursor: pointer; /* Add cursor change for button hover */
  }

  .all-button:hover,
  .conversations-button:hover,
  .contacts-button:hover,
  .friend-requests-button:hover {
    border-color: #007bff; /* Add hover effect */
  }

  .active {
    background-color: #007bff; /* Highlight color */
    color: white;
    border-color: #007bff; /* Match border to background */
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #00000035;
}

.contactImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-weight: 500;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }
}

.lastMessage {
  font-size: 14px;
}

.contactUsername {
  font-size: 16px;
  font-weight: bold;
}

.chat-list-loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.chat-list-spinner {
  margin-top: 100%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
