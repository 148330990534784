.modal-overlay-user {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.update-description {
  font-size: 16px;
}

.modal-content-user {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
}

.modal-close-user {
  position: relative;
  top: -9px;
  right: -230px;
  background: none;
  font-size: 20px;

  border: none;
  cursor: pointer;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px; /* Space below the textarea */
  border-radius: 4px;
  border: 1px solid #ddd;
  resize: none;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  outline: none;
}

textarea:focus {
  border-color: #777777; /* Change this color to the desired grey */
}

.submitButton-user {
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content-user {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    text-align: center;
  }

  .modal-close-user {
    position: relative;
    left: 50%;
    background: none;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }
}
