.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .user {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #00000035;

    .details-contact-username {
      display: flex;
      font-size: 20px;
      font-weight: bold;
    }

    .scrollable-paragraph {
      /* font-size: 16px; */
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    .scrollable-paragraph {
      max-height: 150px; /* Adjust the height as needed */
      overflow-y: auto; /* Enables vertical scrolling */
      padding: 10px; /* Optional: Add padding for better readability */
      border: 1px solid #ccc; /* Optional: Add border to visually define the area */
      border-radius: 5px;
    }
  }

  .info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: scroll;

    .option {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .option-title {
          font-size: 16px;
        }
      }
      .optionArrow {
        width: 30px;
        height: 30px;
        background-color: rgba(17, 25, 40, 0.3);
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }

      .margin-from-top {
        margin-top: 20px;
      }

      .photos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        max-height: 220px;
        overflow-y: scroll;

        .photoItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          position: relative; /* To position the download icon absolutely */

          .photoDetail {
            display: flex;
            align-items: center;
            gap: 20px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 5px;
              object-fit: cover;
              transition: opacity 0.3s ease; /* Smooth transition for opacity change */
            }
          }

          .downloadArrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            /* background-color: rgba(17, 25, 40, 0.7);  */
            padding: 2px;
            border-radius: 50%;
            cursor: pointer;
            opacity: 0; /* Initially hidden */
            transition: opacity 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              color: white; /* Icon color */
              font-size: 20px; /* Adjust icon size if needed */
            }
          }

          &:hover img {
            opacity: 0.7; /* Lower image opacity on hover */
          }

          &:hover .downloadArrow {
            opacity: 1; /* Show the download icon on hover */
          }
        }
      }
    }

    button {
      padding: 10px 20px;
      background-color: rgba(218, 37, 37, 0.6);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: rgba(218, 37, 37, 1);
      }

      &.logout {
        margin-top: -20px;
        padding: 10px;
        background-color: rgba(81, 131, 254, 0.6);

        &:hover {
          background-color: rgba(81, 131, 254, 1);
        }
      }
    }
  }
}
