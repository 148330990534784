/* NotificationModal.css */

/* Full-screen modal container */
.notification-modal-container {
  position: fixed;
  top: -100px; /* Start off-screen */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  z-index: 5; /* Ensure it's above everything */
  transition: top 0.5s ease; /* Smooth slide */
  pointer-events: none; /* Prevent interaction with the entire container */
}

.notification-modal-container.slide-in {
  top: 20px; /* Slide into view */
}

.notification-modal-container.slide-out {
  top: -100px; /* Slide back up to hide */
}

.notification-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center text horizontally */
  position: relative;
  z-index: 5; /* Ensure it's above everything */
  pointer-events: auto; /* Allow interaction with the modal content */
}

/* Center text vertically in the content */
.notification-message {
  margin: 0px 10px 0px 10px;
  font-size: 16px;
}

/* Close button with countdown */
.close-btn {
  position: absolute; /* Change to relative for correct positioning */
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none; /* Remove outline on focus */
}

.progress-line {
  height: 5px; /* Height of the progress line */
  position: absolute;
  top: 0; /* Position at the top of the modal */
  left: 0; /* Start from the left */
  transition: width 1s linear; /* Smooth filling effect */
  border-top-left-radius: 8px; /* Rounded top left corner */
  border-top-right-radius: 8px; /* Rounded top right corner */
}
