.wrapper .input-box-email-forgot {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0px 0px 20px 0px;
}

.input-box-email-forgot input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-email-forgot .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.wrapper .login-link {
  font-size: 14.5px;
  text-align: center;
  margin: 20px 0 0px;
}

.login-link p a {
  text-decoration: none;
  font-weight: 600;
}

.login-link p a:hover {
  text-decoration: underline;
}
