.search {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid #00000035;
}

.search .searchBar {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid #00000035;
  padding: 10px;
}

.search .addIcon {
  display: flex;
  align-items: center;
}

.search input {
  flex: 1;
  border: none;
  outline: none;
}

.search .searchImg {
  width: 20px;
  height: 20px;
}

.search .plusImg {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.badge {
  position: relative;
  display: flex;
}

.friend-number {
  font-size: 14px;
}
