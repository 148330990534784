.userInfo {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000035;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-info-username {
  display: flex;
  font-size: 20px;
  font-weight: bold;
}

.avatar-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.icons {
  display: flex;
  gap: 20px;
}

.options {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
