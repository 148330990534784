.wrapper .input-box-email-reg {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0px 0px 0px 0px;
}

.input-box-email-reg input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-email-reg .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.wrapper .input-box-username {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 5px 0px 0px 0px;
}

.input-box-username input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-username .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.wrapper .input-box-password {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 5px 0px 5px 0px;
}

.input-box-password input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-password .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.wrapper .input-box-confirm-password {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 5px 0px 30px 0px;
}

.input-box-confirm-password input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 1px solid rgba(3, 3, 3, 0.589);
  border-radius: 40px;
  font-size: 16px;
  padding: 20px 45px 20px 20px;
}

.input-box-confirm-password .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.empty-line {
  margin: 24px 0;
}
